@media print {
    .media-object-list .media-object img.media-object__thumbnail {
        max-width: 150px !important;
        // max-height: 130px;
        margin-bottom: 1rem;
    }

    .figure.figure--one-of-two,
    .figure.figure--one-of-two img {
        max-width: 269px !important;
    }

    a[href]:after {
        content: '';
        display: none;
    }

    .logo.logo--print {
        display: block;
        position: absolute;
        top: 0;
        left: calc(50% + 1024px / 2 - 130px * 1.5);
        z-index: 1;
        background-color: #fff !important;
        background-image: url("/assets/img/versioned/logo_x1.png") !important;
        background-size: 130px 156px !important;
        width: 130px;
        height: 156px;
        }

    .banner-text {
        margin: auto;
        max-width: 1024px;
        width: 100%;
        padding-left: .5rem;
        padding-right: .5rem;
    }

    .container {
        display: block;
    }
    main {
        flex: none;
        overflow: visible;
    }

    article {
        a[href]:not(.hide-url-for-print):after {
            content: " (" attr(href) ")";
            display: inline;
        }
    }

    .footer__list {
        display: none;
    }

    footer .footer__menu .menu {
        li:not(:last-child) {
            display: none;
        }
    }

    footer.footer--padded {
        padding-bottom: 0 !important;
    }

    .footer__blurb a {
        display: none;
    }
}
